import React from 'react';
import { graphql } from 'gatsby';
import ServicesLayout from '../components/ServicesLayout';
import SEO from '../components/SEO';

export default function ResidentialPage({ data, location }) {
  return (
    <>
      <SEO title={'Residential Projects'} />
      <ServicesLayout
        bg={data.sanityServicesPages}
        bgAlt={data.sanityServicesPages.headerImage.altText}
        heading={data.sanityServicesPages.name}
        subText={data.sanityServicesPages.description}
        applications={data.sanityServicesPages.applications}
        galleryImages={data.sanityServicesPages.galleryImages}
        location={location}
      />
    </>
  );
}

export const { data } = graphql`
  query ResidentialPageQuery {
    sanityServicesPages(id: { eq: "-79d4d002-f8e5-5ca7-a513-4e1601bea195" }) {
      headerImage {
        asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], aspectRatio: 1.7778)
        }
        altText
      }
      description
      name
      applications {
        icon {
          asset {
            gatsbyImageData(height: 72)
          }
        }
        name
        id
      }
      galleryImages {
        asset {
          thumb: gatsbyImageData(width: 195, height: 195)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
        altText
        imageCaption
      }
    }
  }
`;
